import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueCookies from "vue-cookies";

Vue.use(Vuex);
Vue.use(VueCookies);

axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    let cookiesDomain = window.location.hostname;
    VueCookies.remove("admin-token", null, cookiesDomain.replace("www.", ""));
    // if (VueCookies.isKey("admin-token")) {
    //   VueCookies.remove("admin-token", null, `${process.env.WEB_HOST}`);
    // }
    window.location.href = "/";
  }
  return error;
});

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  globalLanguages: [],
  templateId: "",
  templateDetail: {
    entity: {
      id: 0,
      imageUrl: "",
      text: "",
      name: "",
      startDateTime: "",
      endDateTime: "",
      status: false,
      createdTime: "",
      updatedTime: "",
      createdBy: "",
      updatedBy: "",
    },
    sections: [],
  },
  bannerDetail: {
    id: 0,
    name: "",
    displayName: null,
    sortOrder: 0,
    typeId: 1,
    type: "แบนเนอร์",
    productOptionTypeId: 0,
    productOptionType: null,
    displayTypeId: 0,
    displayType: null,
    categoryIds: [],
    status: false,
    details: [],
  },
  indexBannerDetail: null,
  email: "",
  promotionType: "",
  step: 0,
  profile: {
    email: "",
    firstname: "",
    lastname: "",
    displayNameTranslation: [
      { languageId: 1, name: "" },
      { languageId: 2, name: "" },
    ],
    statusId: 0,
    enabled: false,
  },
  mainSetting: {
    isSignature: false,
    isCreateCategory: false,
  },
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    Vue.set(state, variable, !state[variable]);
    // state[variable] = !state[variable]
  },
  setGlobalLanguages(state, list) {
    list.forEach((item) => {
      state.globalLanguages.push({ ...item });
    });
  },
  setEmail(state, val) {
    state.email = val;
  },
  setTemplateId(state, val) {
    state.templateId = val;
  },
  setTemplateDetail(state, val) {
    state.templateDetail = val;
  },
  setIndexBannerDetail(state, val) {
    state.indexBannerDetail = val;
  },
  setBannerDetail(state, val) {
    if (val) {
      if (typeof val == "object") {
        if (val.flag) {
          if (val.index != undefined) {
            state.bannerDetail.details[val.index] = val.data;
          } else {
            state.bannerDetail.details.push(val.data);
          }
        } else {
          state.bannerDetail.details = [];
          state.bannerDetail.details = val.data;
        }
      } else state.bannerDetail.name = val;
    } else {
      state.bannerDetail = {
        id: 0,
        name: "",
        displayName: null,
        sortOrder: 0,
        typeId: 1,
        type: "แบนเนอร์",
        productOptionTypeId: 0,
        productOptionType: null,
        displayTypeId: 0,
        displayType: null,
        categoryIds: [],
        status: false,
        details: [],
      };
    }
  },
  setPromotionType(state, val) {
    state.promotionType = val;
  },
  setStep(state, val) {
    state.step = val;
  },
  setShortProfile(state, val) {
    state.profile = val;
  },
  setSetting(state, val) {
    let signature = val.filter((item) => item.id == 95);
    if (signature.length) {
      state.mainSetting.isSignature = signature[0].value == "1" ? true : false;
    }
    let isCreateCategory = val.filter((item) => item.id == 96);
    if (isCreateCategory.length) {
      state.mainSetting.isCreateCategory =
        isCreateCategory[0].value == "1" ? true : false;
    }
  },
};

const actions = {
  getUserApi: async ({ commit, rootGetters }, payload) => {
    if (VueCookies.isKey("admin-token")) {
      await axios({
        url: `${Vue.prototype.$baseUrl}/api/shortProfile`,
        headers: Vue.prototype.$headers,
        method: "get",
      }).then((res) => {
        let data = res.data;
        if (data.result) {
          commit("setShortProfile", data.detail.userDetail);
        }
        // else {
        //   let cookiesDomain = window.location.hostname;
        //   VueCookies.remove("admin-token", null, cookiesDomain.replace("www.", ""));
        //   // if (VueCookies.isKey("admin-token")) {
        //   //   VueCookies.remove("admin-token", null, `${process.env.WEB_HOST}`);
        //   // }
        //   commit("setShortProfile", false);
        // }
      });
    }
  },
  getMainSetting: async ({ commit, rootGetters }) => {
    if (VueCookies.isKey("admin-token")) {
      await axios({
        url: `${Vue.prototype.$baseUrl}/api/Setting/GetValueList`,
        headers: Vue.prototype.$headers,
        method: "get",
      }).then((res) => {
        let data = res.data;
        if (data.result) {
          commit("setSetting", data.detail);
        }
      });
    }
  },
};

const getters = {};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
